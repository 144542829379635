import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import Layout from "../layouts"
import Head from "../components/head"
import DefaultHero from "../components/default-hero"

import YoastSeoParts from '../fragments/seo';

class PageTemplate extends Component {
  
  render() {
    const webinars = this.props.data.wpPastWebinar;
    const { title, link, excerpt, content, featuredImage, cptPastWebinar, seo } = webinars;

    if(cptPastWebinar.startDateTime) {
      let datetime  = new Date(cptPastWebinar.startDateTime)
      let timezone = 'America/New_York';

      let date = datetime.toLocaleString('en-US', {
        timeZone: timezone,
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });

      let time = datetime.toLocaleString('en-US', {
        timeZone: timezone,
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      });
    }

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <div className="container blogSingle">
          <div className="grid smallGrid">
            <div className="col-8 maincopy">
              <p className="blogDetails">January 21, 2021</p>
              <div className="grid align-end">
                <div className="col-sm-8">
                  <h1>{title}</h1>
                </div>
                <div className="col-sm-4">
                  
                  <div className="socialBlog">
                    <span>Share This:</span>
                    <a href={"https://www.facebook.com/share.php?u="+link}><FontAwesomeIcon icon={faFacebookF} /></a>
                    <a href={"http://www.twitter.com/share?url="+link+"&count=horizontal"}><FontAwesomeIcon icon={faTwitter} /></a>
                    <a href={"https://www.linkedin.com/shareArticle?mini=true&url="+link+"&title="+title+"&summary=&source=LinkedIn"}><FontAwesomeIcon icon={faLinkedinIn} /></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-8 maincopy">
              { (featuredImage && featuredImage.node) &&
                <img src={featuredImage.node.localFile.publicURL} className="flex-img mainImage" />
              }
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
              { cptPastWebinar.moreInformationLink &&
                <a className="mainCTA" href={cptPastWebinar.moreInformationLink.url} target={cptPastWebinar.moreInformationLink.target}>{cptPastWebinar.moreInformationLink.title ? cptPastWebinar.moreInformationLink.title : 'Request More Info'}</a>
              }
            </div>
            <div className="col-sm-4">
              <OnlineEvent datetime={cptPastWebinar.startDateTime} link={cptPastWebinar.webinarLink} />

              <hr className="sidebarDivider" />

              <div className="socialBlog">
                <span>Share This:</span>
                <a href={"https://www.facebook.com/share.php?u="+link}><FontAwesomeIcon icon={faFacebookF} /></a>
                <a href={"http://www.twitter.com/share?url="+link+"&count=horizontal"}><FontAwesomeIcon icon={faTwitter} /></a>
                <a href={"https://www.linkedin.com/shareArticle?mini=true&url="+link+"&title="+title+"&summary=&source=LinkedIn"}><FontAwesomeIcon icon={faLinkedinIn} /></a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default PageTemplate

function InPersonDetails ({datetime, image, address, address2, city, state, zip}) {

  datetime  = new Date(datetime)
  let timezone = 'America/New_York';

  let date = datetime.toLocaleString('en-US', {
    timeZone: timezone,
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  let time = datetime.toLocaleString('en-US', {
    timeZone: timezone,
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });

  return (
    <React.Fragment>
      
        <p className="blogSidebarTitle">Event Details</p>

        
        { (date && date != 'Invalid Date') && 
          <React.Fragment>
            <p className="eventSubTitle eventSubTitleTop"><strong>Start Date</strong></p>
            <p className="eventSubTitle">{date}</p>
            <hr className="sidebarDivider" />
          </React.Fragment>
        }

        { (time && time != 'Invalid Date') && 
          <React.Fragment>
            <p className="eventSubTitle eventSubTitleTop"><strong>Time</strong></p>
            <p className="eventSubTitle">{time}</p>
            <hr className="sidebarDivider" />
          </React.Fragment>
        }

        <p className="eventSubTitle eventSubTitleTop"><strong>Location</strong></p>
        <p className="eventSubTitle">
          {address}<br />
          {address2 && <React.Fragment>{address2}<br /></React.Fragment>}
          {city}, {state} {zip}
        </p>
    </React.Fragment>
  )
}

function OnlineEvent({datetime, link}) {

  datetime  = new Date(datetime)
  let timezone = 'America/New_York';

  let date = datetime.toLocaleString('en-US', {
    timeZone: timezone,
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  let time = datetime.toLocaleString('en-US', {
    timeZone: timezone,
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });

  return (
    <React.Fragment>

      <p className="blogSidebarTitle">Webinar Details</p>

              
      { date != 'Invalid Date' && 
        <React.Fragment>
          <p className="eventSubTitle eventSubTitleTop"><strong>Webinar Date</strong></p>
          <p className="eventSubTitle">{date}</p>
          <hr className="sidebarDivider" />
        </React.Fragment>
      }

      { time != 'Invalid Date' && 
        <React.Fragment>
          <p className="eventSubTitle eventSubTitleTop"><strong>Time</strong></p>
          <p className="eventSubTitle">{time}</p>
          <hr className="sidebarDivider" />
        </React.Fragment>
      }

      <p className="eventSubTitle eventSubTitleTop"><strong>Webinar Link</strong></p>
      <p className="eventSubTitle">
        <a href={link} target="_blank">{link}</a>
      </p>
    </React.Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!) {
    wpPastWebinar(id: {eq: $id}) {
      title
      link
      uri
      id
      content
      seo {
        ...seoParts
      }
      featuredImage {
        node {
          altText
          localFile {
            publicURL
          }
        }
      }
      cptPastWebinar {
        startDateTime
        webinarLink
        moreInformationLink {
          title
          url
          target
        }
      }
    }
  }
`